




































































































import Vue from "vue";

export default Vue.extend({
  name: "App",

  data: () => ({
    app_name: process.env.VUE_APP_TITLE,
    toolbar_items: [
      { label: "Home", route: "Home" },
      { label: "Analyse", route: "Demonstration" },
      { label: "Dashboard", route: "Dashboard" },
      { label: "Kwaliteitsregels", route: "Rules" },
      { label: "Bevindingen", route: "Findings" },
      { label: "Brondocumenten", route: "Documents" },
      { label: "Leveringen", route: "Batches" },
      { label: "Projectnummmers", route: "Projects" },
    ],
  }),
});
